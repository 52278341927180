<template>
    <section>
        <modal ref="modalViewTask" titulo="Detalle de la tarea" tamano="modal-lg">
            <div v-if="Object.values(detalleTarea).length" class="row m-3">
                <div class="col-lg-2">
                    <div class="bg-f5 br-4 mt-2">
                        <div class="bg-5d f-12 text-white br-t-4 text-center">{{ detalleTarea.created_at | helper-fecha('MMM') }}</div>
                        <div class="f-18 f-700 d-middle-center border-bottom border-right border-left py-1">
                            {{ detalleTarea.created_at | helper-fecha('DD') }}
                        </div>
                    </div>
                </div>
                <div class="col-10">
                    <div class="row titulo-divisor">
                        <div class="col-auto my-auto">
                            <p class="f-600 f-14 ucfirst text-general" >{{ detalleTarea.nombre_mantenimiento }}</p>
                        </div>
                        <div class="col my-auto pl-0">
                            <hr class="my-0 bg-white" />
                        </div>
                        <div class="col-auto my-auto d-none">
                            <div class="row">
                                <div class="col-auto my-auto px-1 icon-option">
                                    <el-tooltip content="Editar" placement="bottom" effect="light" visible-arrow>
                                        <i class="icon-pencil mr-2 cr-pointer f-20" @click="editTask" />
                                    </el-tooltip>
                                    <el-tooltip content="Eliminar" placement="bottom" effect="light" visible-arrow>
                                        <i class="icon-trash-can-outline mr-2 cr-pointer f-20" @click="deleteTask" />
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <p class="col-12 f-12">{{ detalleTarea.created_at | helper-fecha('hh:mm A') }} - {{formatHourAddHour(detalleTarea.created_at)}}</p>
                    </div>
                    <div class="row mb-3">
                        <div class="col-auto">
                            <div class="bg-white border rounded-pill d-middle tres-puntos my-1">
                                <img :src=" detalleTarea.user.foto | helper-storage"  alt="" class="wh-26 rounded-pill bg-white">
                                <p class="ml-2 f-12 tres-puntos">{{ detalleTarea.user.nombre }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3 d-none">
                        <div class="col-6">
                            <p class="f-600 f-14">Tipo de actividad</p>
                            <p class="f-12">Comercial</p>
                        </div>
                        <div class="col-6">
                            <p class="f-600 f-14">Subtipo de actividad</p>
                            <p class="f-12">Marketing</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 f-12">
                            <p class="f-600">Descripción</p>
                            <p class="f-600">{{ detalleTarea.descripcion }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <template slot="end-buttons">
                <button type="button" class="btn ml-1 mr-2 f-12 btn-eliminar f-300" @click="aceptar">
                    Aceptar
                </button>
            </template>
        </modal>
        <modal-eliminar-tarea ref="modalDeleteTask" />
        <modal-crear-tarea ref="modalCreateTask" />
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment';
import { HelperFecha } from '../../../filters/global';
export default {
    components:{
         modalEliminarTarea: () => import('./modalEliminarTarea.vue'),
         modalCrearTarea: () => import('./modalCrearTarea.vue'),
    },
    data(){
        return {
            
        }
    },
    computed: {
        ...mapGetters({
            detalleTarea: 'calendario/calendario/detalleTarea',
        }),
    },
    methods: {
        ...mapActions({
            Action_get_detalle_tarea: 'calendario/calendario/Action_get_detalle_tarea',
        }),
        formatHourAddHour(fecha){
            return moment(fecha).add(1,'H').format('hh:mm A') 
        },
        async toggle(value){
            await this.Action_get_detalle_tarea(value.id)
            this.$refs.modalViewTask.toggle()
        },
        aceptar(){
            this.$refs.modalViewTask.toggle()
        },
        editTask(){
            this.$refs.modalCreateTask.toggle()
            this.$refs.modalViewTask.toggle()
        },
        deleteTask(){
            this.$refs.modalDeleteTask.toggle()
            this.$refs.modalViewTask.toggle()
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
